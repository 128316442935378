import { defineStore } from 'pinia'
import { type ICounterparty } from '~/types/ICounterparty'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

export const useCounterpartiesByNameStore = defineStore('counterpartiesName', () => {
  const counterparty_name = ref('')
  const counterparty_id = ref('')
  const counterparties = ref<ICounterparty[]>([])
  const pending = ref(false)
  const error = ref(null)

  const fetch = async () => {
    const requestOptions = {
      query: {
        counterparty_id: counterparty_id.value
      },
      immediate: false,
      watch: false
    } as Record<string, any>

    if (counterparty_name.value.length > 0) {
      requestOptions.query['name'] = counterparty_name.value
    }

    const { execute } = await useAuthenticatedFetch<ICounterparty[]>(`/api/v1/shop/counterparties`, {
      ...requestOptions,
      onRequest() {
        pending.value = true
      },
      onResponse(context) {
        pending.value = false
        counterparties.value = context.response._data
      }
    })
    await execute()
  }
  const reset = () => {
    counterparties.value = []
  }
  return {
    counterparty_name,
    counterparty_id,
    counterparties,
    pending,
    error,
    reset,
    fetch
  }
})
